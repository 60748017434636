.bg-dark-blue {
  background: #153b62 !important;
}

.fs-small {
  font-size: small;
}

.fs-medium {
  font-size: 14.5px;
}
.border-bm-dotted {
  border: none;
  border-bottom: 2px solid lightgrey;
  border-bottom-style: dotted;
}

.ql-editor {
  min-height: 10rem !important;
}

.content-btn {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.content-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.form {
  &:hover {
    background-color: #f9e9de;
  }
}

.active-form {
  background-color: #f76705;
  color: white;
}
